import React from 'react';
import Router from 'next/router';
import withStore from 'utils/withStore';
import Authentication from 'stores/authentication';

class Page extends React.Component {
  async componentDidMount() {
    const isLoggedIn = await this.props.authenticationStore.isLoggedIn;

    if (!isLoggedIn) {
      return Router.push('/login');
    }

    return Router.push('/dashboard');
  }

  render() {
    return (
      <div className="pageloader is-active">
        <span className="title" />
      </div>
    );
  }
}

export default withStore(Authentication)(Page);
