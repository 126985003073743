import { GraphQLClient } from 'graphql-request';
import Cookie from 'js-cookie';

const endpoint = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'http://localhost:4000';

const voizGraphqlClient = (token) => {
  if (!token) {
    token = Cookie.get('auth_token');
  }

  const client = new GraphQLClient(`${endpoint.replace('/graphql', '')}/graphql`);
  client.setHeader('X-User-Agent', 'Voiz Admin')

  if (token) {
    client.setHeader('Authorization', `Bearer ${token}`);
  }

  return {
    query: (query, variables = {}) => client.request(query, variables),
    mutation: (query, variables = {}) => client.request(query, variables),
  };
};

export default voizGraphqlClient;
