import React from 'react';
import { observable } from 'mobx';
import { enableStaticRendering, Provider, inject, observer } from 'mobx-react';
import cookies from 'next-cookies';
import get from 'lodash/get';
import getLoggedInUser from './get-logged-in-user.js'

const isServer = typeof window === 'undefined';
const __NEXT_MOBX_STORES__ = '__NEXT_MOBX_STORES__';
const clientStores = {};
enableStaticRendering(isServer);

const lcfirst = (string) => string[0].toLowerCase() + string.substring(1);
const getStoreName = (store) => `${lcfirst(store)}Store`;

const initStore = (Store) => (isServer, context) => {
  if (isServer) {
    return new Store(context);
  }

  if (typeof clientStores[getStoreName(Store.storeName)] === 'undefined') {
    clientStores[getStoreName(Store.storeName)] = new Store(context);
  }

  return clientStores[getStoreName(Store.storeName)];
};

const getOrCreateStore = (Store, storeName, initialState) => {
  if (isServer) {
    return initStore(Store)(isServer, initialState);
  }

  if (typeof window[__NEXT_MOBX_STORES__] === 'undefined') {
    window[__NEXT_MOBX_STORES__] = {};
  }

  if (typeof window[__NEXT_MOBX_STORES__][storeName] === 'undefined') {
    window[__NEXT_MOBX_STORES__][storeName] = initStore(Store)(
      isServer,
      initialState,
    );
  }

  return window[__NEXT_MOBX_STORES__][storeName];
};

const withStore = (...stores) => (ChildComponent) => class extends React.Component {
    static displayName = 'withStore';

    stores = observable({});

    static async getInitialProps(context) {
      const token = get(cookies(context), 'auth_token', undefined);
      const loggedInUser = token ? await getLoggedInUser(token) : null;

      let initializedStores = observable({});

      [...stores].forEach((store) => {
        initializedStores = {
          ...initializedStores,
          [getStoreName(store.storeName)]: getOrCreateStore(
            store,
            getStoreName(store.storeName),
            {
              token,
              loggedInUser,
            },
          ),
        };
      });

      return {
        isServer,
        stores: initializedStores,
        ...(typeof ChildComponent.getInitialProps === 'function'
          ? await ChildComponent.getInitialProps.call(ChildComponent, {
            ...context,
            isServer,
            stores: initializedStores,
          })
          : {}),
      };
    }

    constructor(props) {
      super(props);

      [...stores].forEach((store) => {
        this.stores = {
          ...this.stores,
          [getStoreName(store.storeName)]: getOrCreateStore(
            store,
            getStoreName(store.storeName),
            props.stores[getStoreName(store.storeName)],
          ),
        };
      });
    }

    render() {
      let Component;

      if (ChildComponent.isMobxStoreInited) {
        Component = inject.apply(() => {}, Object.keys(this.stores))(
          ChildComponent,
        );
      } else {
        Component = inject.apply(() => {}, Object.keys(this.stores))(
          observer(ChildComponent),
        );
        ChildComponent.isMobxStoreInited = true;
      }

      return React.createElement(
        Provider,
        this.stores,
        React.createElement(Component, this.props),
      );
    }
};

export default withStore;
