import {
  observable, action, makeObservable, computed,
} from 'mobx';
import to from 'await-to';
import Cookie from 'js-cookie';
import client from 'utils/graphql';

import login from 'graphql/mutations/login.gql';
import me from 'graphql/queries/me.gql';

export default class Authentication {
  static storeName = 'Authentication';

  client = null;

  token = null;

  loggedInUser = null;

  constructor(context) {
    makeObservable(this, {
      loggedInUser: observable,
      isLoggedIn: computed,
      login: action.bound,
      logout: action.bound,
    });
    Object.assign(this, context);

    this.client = client(this.token);
  }

  get name() {
    return (
      this.loggedInUser.billingName
      || `${this.loggedInUser.lastName} ${this.loggedInUser.firstName}`
    );
  }

  get isLoggedIn() {
    if (this.loggedInUser !== null && this.loggedInUser !== undefined) {
      return true;
    }

    const token = Cookie.get('auth_token');

    if (this.token || token) {
      if (!this.token && token) {
        this.token = token;
      }

      return this.me();
    }

    return false;
  }

  async login(credentials) {
    const query = login.loc.source.body;
    const [error, result] = await to(this.client.mutation(query, credentials));

    if (error) {
      return error.message;
    }

    const { token, user } = result.loginDashboard;

    Cookie.set('auth_token', token);

    this.loggedInUser = {
      id: user.id,
      name: `${user.lastName} ${user.firstName}`,
      email: user.email,
    };

    return null;
  }

  logout() {
    this.loggedInUser = null;
    Cookie.remove('auth_token');
  }

  async me() {
    const query = me.loc.source.body;
    const [error, response] = await to(this.client.query(query));

    if (error) {
      this.logout();

      return false;
    }

    this.loggedInUser = {
      id: response.me.id,
      name:
        response.me.user.billingName
        || `${response.me.user.lastName} ${response.me.user.firstName}`,
      email: response.me.user.email,
    };

    return true;
  }
}
