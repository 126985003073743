import to from 'await-to';
import { GraphQLClient } from 'graphql-request';
import { LRUCache } from 'lru-cache'


const cache = new LRUCache({
  ignoreFetchAbort: true,
  max: 1000,
  ttl: 2500,
  fetchMethod: async (key) => {
    return fetchMe(key)
  }
})

export default async function getLoggedInUser(token) {
  return cache.fetch(token)
}

export async function fetchMe(token) {
  const client = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT + '/graphql', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  const [error, response] = await to(
    client.request(`{ me { user { id, firstName, lastName, billingName, email } } }`)
  );

  if (error)
    return null;

  return response.me?.user;
}
